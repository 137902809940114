@media only screen and (min-width:320px) and (max-width:992px){

  .res-hide{display: none !important;}
  .res-col{flex-direction: column !important;}

  body,html{overflow-x: hidden !important;}

  header{padding: 15px 0px; height: auto;}
  header .part1,header .part3{padding: 0px !important;}
  header .part1{max-width: 40% !important; flex: 0 0 40% !important;}
  header .logo img{width: 35px !important;}
  header .logo span{font-size: 16px;}

  header .part3{max-width: 60% !important; flex: 0 0 60% !important;}


  .main .sidebarWrapper{width: 80% !important; height: 100vh !important; position: fixed; top: 0px; left: -100%; z-index: 10000; opacity: 0; transition:all 0.3s ease-in-out}
  .main .sidebarWrapper .sidebar{width: 100% !important; position: relative !important; top: 0px !important;  max-height:inherit !important;}

  .sidebarWrapper.open{left: 0px !important; opacity: 1 !important;}

.sidebarOverlay{position: fixed; top: 0px; left: 0px; background: rgba(0,0,0,0.7); z-index: 100; width: 100%; height: 100% !important; z-index: 1000;}
.sidebarOverlay.show{display: block !important;}

.searchWrap{width: 100% !important; margin-top: 10px !important;}
.searchBox {width: 100% !important;}

  .main .content{width: 100% !important; flex: 0 0 100% !important;}
  .right-content{padding: 90px 15px !important;}

  .dashboardBoxWrapper{white-space: nowrap !important; overflow-x: scroll; overflow-y: hidden; display: block !important; margin-bottom: 20px !important;}
  .dashboardBoxWrapper::-webkit-scrollbar{display: none !important;}
  .dashboardBoxWrapper .dashboardBox{display: inline-block !important; width: 270px !important; height: 118px !important; margin-right: 10px !important;}
  .topPart2{padding-left: 15px !important;}

  .tableFooter{flex-direction: column;}
  .tableFooter nav{margin-top: 25px;}
  .tableFooter nav ul{white-space: nowrap !important; overflow-x: scroll; overflow-y: hidden; display: block !important; margin-bottom: 20px !important;}
  .tableFooter nav ul li{display: inline-block !important; width: max-content !important; height: auto !important; }

  .card.shadow.border-0.w-100.flex-row{margin-top: 0px !important;}
  .card.shadow.border-0.p-3.mt-4{margin-top: 0px !important;}

  .MuiBreadcrumbs-root{margin-top: 15px !important;}

  .productInfo .col-sm-3{max-width: 200px !important; flex: 0 0 200px !important;}
  .productInfo .col-sm-9{max-width: 100% !important; flex: 0 0 100% !important; padding-left: 36px !important;}

  .loginSection{height: auto !important;}
  .signUpSection .col-md-8{display: none !important;}

  #notifications .MuiPaper-elevation{right: 20px !important;}

  .dashboardBoxWrapperRow{margin-bottom:0px ; padding-top: 0px !important;}

  .MuiBreadcrumbs-root{display: none !important;}

}

@media only screen and (min-width:320px) and (max-width:575px){
  header .row{margin: auto !important;}
  header button.rounded-circle{width: 35px !important; min-width: 35px !important; height: 35px !important; margin-right: 8px !important;}
  header .btn-blue{font-size: 12px !important; padding: 5px 10px !important;}
  .userImg{width: 35px; height: 35px;}

  .myAccWrapper button{padding: 0px !important; min-width: 35px !important; width: 35px !important;}

  .ratingSection{width: 100% !important;}

  .card h5{font-size: 16px !important;}

  .btn-blue{font-size: 11px !important;}
  
  
}



@media only screen and (min-width:320px) and (max-width:767px){
  .form .col{max-width: 100% !important; flex: 0 0 100% !important;}
}

